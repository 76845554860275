<style lang="scss" scoped>
.app {
  height: 100%;
  position: relative;
}

.search-box {
  /* width: 500px; */
  margin: 0 auto;
  height: 40px;
  .search-box-input {
    display: inline-block;
    vertical-align: top;
    width: 400px;
    margin-right: 10px;
  }
}

.tab-box {
  height: 30px;
  padding-left: 20px;
  text-align: left;
  .tab-view {
    display: inline-block;
    vertical-align: top;
    line-height: 30px;
    padding: 0 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: #666;
    &.active {
      color: #409eff;
    }
  }
}

.ft-box {
  text-align: left;
  background: #fff;
  margin: 10px;
  padding-top: 8px;
  .ft-box-row {
    line-height: 28px;
    padding: 8px 60px 0px 100px;
    position: relative;
    border-radius: 4px;
    .ft-box-row-title {
      position: absolute;
      top: 10px;
      left: 0;
      width: 100px;
      font-size: 15px;
      box-sizing: border-box;
      text-align: right;
    }
    .ft-box-class-btn {
      position: absolute;
      top: 10px;
      right: 0;
      width: 50px;
      text-align: center;
      cursor: pointer;
      font-size: 20px;
      box-shadow: -1px 0px 10px #eee;
    }
    .ft-box-class-row {
      max-width: 100%;
      .ft-box-class-body {
        &.overflow {
          white-space: nowrap;
          overflow: hidden;
        }
        .ft-box-class-item {
          display: inline-block;
          cursor: pointer;
          color: #409eff;
          font-size: 14px;
          padding: 4px 10px;
          margin: 0 5px;
          border-radius: 4px;
          &.active {
            color: #fff;
            background: #409eff;
          }
        }
      }
    }
  }
  .ft-box-filter {
    line-height: 1;
    .from-item {
      width: 80px;
    }
  }
}

.filter-box {
  text-align: left;
  padding-left: 9px;
  position: relative;
  .filter-item-icon {
    line-height: 2px;
    display: inline-block;
    vertical-align: top;
    height: 2px;
  }
}

.goods-box {
  padding-top: 20px;
  padding-left: 10px;
}

.goods-list {
  text-align: left;
  .goods-item {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    width: 200px;
    background: #fff;
    margin: 0 10px 10px 0;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  .goods-image {
    height: 200px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.8s;
      &:hover {
        transform: scale(1.5);
      }
    }
    .goods-cpl {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 0;
      background: rgba(0, 0, 0, 0.5);
      .goods-cpl-item {
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
        width: 50%;
        text-align: center;
        cursor: pointer;
        color: #f9f9f9;
        &:hover {
          text-shadow: 1px 1px 1px #fff;
        }
      }
    }
  }
  .goods-title {
    padding: 2px 5px;
    height: 25px;
    line-height: 25px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
  }
  .goods-price-info {
    font-size: 0;
    padding: 0 5px;
    .goods-price-item {
      display: inline-block;
      vertical-align: top;
      width: 33.333333%;
      &:nth-child(2) {
        text-align: center;
      }
      &:nth-child(3) {
        text-align: right;
      }
      .goods-price-item-value {
        font-size: 15px;
        font-weight: bold;
        color: #f00;
      }
      .goods-price-item-label {
        font-size: 12px;
      }
    }
  }
  .goods-tags {
    padding: 5px;
    .goods-tag {
      margin-right: 5px;
    }
  }
  .goods-footer {
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
    span {
      font-size: 12px;
    }
  }
}

.result-box {
  float: right;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
}

.reuslt-text {
  font-size: 12px;
  color: #333;
  padding-right: 10px;
}

.result-join-btn {
  font-size: 12px;
  color: #409eff;
  cursor: pointer;
}
</style>

<template>
  <div class="app" ref="app">
    <div class="search-box">
      专题：
      <el-select v-model="form.topic_id" placeholder="" filterable remote>
        <el-option label="全部" value="">全部</el-option>
        <el-option
          v-for="item in topicList"
          :label="item.topic_name"
          :value="item.topic_id"
          :key="item.topic_id"
        ></el-option>
      </el-select>
      <el-input
        class="search-box-input"
        placeholder="请输入商品标题或商品ID"
        v-model="searchWord"
        @keydown.enter.native="refresh"
      >
      </el-input>
      <el-button type="primary" @click="refresh">搜索</el-button>
      <el-dropdown style="float: right">
        <el-button type="primary">
          添加商品<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="addEditDialog(0)"
            >手动添加</el-dropdown-item
          >
          <el-dropdown-item @click.native="jump(0)"
            >淘宝物料ID</el-dropdown-item
          >
          <el-dropdown-item @click.native="jump(1)">Excel导入</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="ft-box">
      <div class="ft-box-row" v-if="classNavs.length > 0">
        <div class="ft-box-row-title">所属类目：</div>
        <div class="ft-box-class-row">
          <div
            class="ft-box-class-body"
            :class="{ overflow: ftBoxClassBodyOverflow }"
          >
            <div
              class="ft-box-class-item"
              :class="{ active: item.id == ftBoxClassId }"
              v-for="item of classNavs"
              :key="item.id"
              @click="ftBoxClassIdChange(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="ft-box-class-btn" @click="ftBoxClassBodyOverflowChange">
          <i
            :class="{
              'el-icon-caret-bottom': ftBoxClassBodyOverflow,
              'el-icon-caret-top': !ftBoxClassBodyOverflow,
            }"
          ></i>
        </div>
      </div>
      <div class="ft-box-row row2">
        <div class="ft-box-row-title">价格：</div>
        <div class="ft-box-filter">
          <el-form :inline="true" ref="form" size="mini" :model="form">
            <el-form-item prop="min_price" :rules="rules.min_price">
              <el-input
                @input="inputChange"
                class="from-item"
                v-model="form.min_price"
              >
                <template slot="prefix">¥</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="max_price" label="-" :rules="rules.max_price">
              <el-input
                @input="inputChange"
                class="from-item"
                v-model="form.max_price"
              >
                <template slot="prefix">¥</template>
              </el-input>
            </el-form-item>

            <el-form-item
              prop="min_commission_rate"
              label="佣金比例"
              :rules="rules.max_price"
            >
              <el-input
                @input="inputChange"
                class="from-item"
                v-model="form.min_commission_rate"
              >
                <template slot="prefix">%</template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="max_commission_rate"
              label="-"
              :rules="rules.max_commission_rate"
            >
              <el-input
                @input="inputChange"
                class="from-item"
                v-model="form.max_commission_rate"
              >
                <template slot="prefix">%</template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="platform_commission"
              label="佣金≥"
              :rules="rules.platform_commission"
            >
              <el-input
                @input="inputChange"
                class="from-item"
                v-model="form.platform_commission"
              >
                <template slot="prefix">¥</template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="min_coupon_amount"
              label="券面额"
              :rules="rules.min_coupon_amount"
            >
              <el-input
                @input="inputChange"
                class="from-item"
                v-model="form.min_coupon_amount"
              >
                <template slot="prefix">¥</template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="max_coupon_amount"
              label="-"
              :rules="rules.max_coupon_amount"
            >
              <el-input
                @input="inputChange"
                class="from-item"
                v-model="form.max_coupon_amount"
              >
                <template slot="prefix">¥</template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="month_sales"
              label="月销量≥"
              :rules="rules.month_sales"
            >
              <el-input
                @input="inputChange"
                class="fromitem"
                v-model.number="form.month_sales"
              >
              </el-input>
            </el-form-item>
            <!-- <el-form-item prop="month_sales" label="专题id" :rules="rules.month_sales">
    <el-select v-model="form.topic_id" placeholder="">
        <el-option label="全部" value="">全部</el-option>
        <el-option v-for="item in topicList" :label="item.topic_name" :value="item.topic_id"></el-option>
    </el-select>
</el-form-item> -->
          </el-form>
        </div>
      </div>
    </div>

    <div class="filter-box">
      <el-radio-group v-model="filterValue" size="mini" @change="refresh">
        <el-radio-button label="0">默认</el-radio-button>
        <el-radio-button label="1"
          >佣金比例<i class="el-icon-caret-top"></i
        ></el-radio-button>
        <el-radio-button label="2"
          >佣金比例<i class="el-icon-caret-bottom"></i
        ></el-radio-button>
        <el-radio-button label="3"
          >价格<i class="el-icon-caret-top"></i
        ></el-radio-button>
        <el-radio-button label="4"
          >价格<i class="el-icon-caret-bottom"></i
        ></el-radio-button>
        <el-radio-button label="5"
          >销量<i class="el-icon-caret-top"></i
        ></el-radio-button>
        <el-radio-button label="6"
          >销量<i class="el-icon-caret-bottom"></i
        ></el-radio-button>
      </el-radio-group>
      <div class="result-box">
        <span class="reuslt-text"
          >最近2小时共更新{{ total | goodsCount }}件商品</span
        >
        <span class="result-join-btn" @click="showAddTopicDialog"
          >加入专题</span
        >
      </div>
    </div>

    <div class="goods-box">
      <div class="goods-list">
        <div
          class="goods-item"
          v-for="item of goodList"
          :key="item.item_id"
          @click="showDetails(item)"
        >
          <div class="goods-image">
            <img :src="item.item_pic" />
            <div class="goods-cpl">
              <div class="goods-cpl-item">加入专题</div>
              <div class="goods-cpl-item" @click.stop="addEditDialog(item.id)">
                编辑商品
              </div>
            </div>
          </div>
          <div class="goods-title">
            {{ item.item_title }}
          </div>
          <div class="goods-price-info">
            <div class="goods-price-item">
              <div class="goods-price-item-value">{{ item.end_price }}</div>
              <div class="goods-price-item-label">券后</div>
            </div>
            <div class="goods-price-item">
              <div class="goods-price-item-value">
                {{ item.platform_commission }}
              </div>
              <div class="goods-price-item-label">佣金</div>
            </div>
            <div class="goods-price-item">
              <div class="goods-price-item-value">
                {{ item.commission_rate }}%
              </div>
              <div class="goods-price-item-label">比例</div>
            </div>
          </div>
          <div class="goods-tags" v-if="item.coupon_info">
            <el-tag
              class="goods-tag"
              type="warning"
              size="mini"
              v-for="(item, index) of item.coupon_info"
              :key="index"
              >{{ item.description }}</el-tag
            >
          </div>
          <div class="goods-footer">
            <span>销量 {{ item.month_sales }}</span>
            <span>{{ item.shop_title }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      background
      layout="total, sizes,prev, pager, next,jumper"
      :total="total"
      :page-size="pagesize"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

    <detail
      v-if="showDetailsDialog"
      :show.sync="showDetailsDialog"
      :pDetail="detailsData"
    ></detail>
    <edit v-if="showEditDialog" :show.sync="showEditDialog" :id="editId"></edit>

    <joinTopic
      :params="showSaveTopicDialogParams"
      v-if="showSaveTopicDialog"
      @close="showSaveTopicDialog = false"
    ></joinTopic>
  </div>
</template>


<script>
import { Loading } from "element-ui";
import * as api from "@/config/api";
import detail from "../Details/index.vue";
import edit from "../Edit/index.vue";
import joinTopic from "../JoinTopic/index.vue";
export default {
  name: "goodLibList",
  components: {
    detail,
    edit,
    joinTopic,
  },
  filters: {
    goodsCount(v) {
      console.log(v);
      if (v < 10000) {
        return v;
      }
      return parseInt(v / 10000) + "w+";
    },
  },
  data() {
    const checkFloatNumber = (rule, value, callback, errorMsg) => {
      const r = /^[0-9]([0-9]+)?\.?([0-9]+)?$/;
      if (value && !r.test(value)) {
        this.form[rule.field] = "";
        callback(new Error(errorMsg));
      } else {
        callback();
      }
    };

    const floatRules = [
      {
        validator(rule, value, callback) {
          checkFloatNumber(rule, value, callback, "只能是数字");
        },
        trigger: "blur",
      },
    ];
    return {
      showSaveTopicDialog: false,
      searchWord: "",
      classNavs: [],
      ftBoxClassId: "-1",
      ftBoxClassBodyOverflow: true,
      filterValue: "0",
      form: {
        min_price: "",
        max_price: "",
        min_commission_rate: "",
        max_commission_rate: "",
        platform_commission: "",
        month_sales: "",
        min_coupon_amount: "",
        max_coupon_amount: "",
        topic_id: "",
      },
      rules: {
        min_price: floatRules,
        max_price: floatRules,
        min_commission_rate: floatRules,
        max_commission_rate: floatRules,
        platform_commission: floatRules,
        month_sales: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (!Number.isInteger(value) || !(value > 0)) {
                  this.form[rule.field] = "";
                  callback(new Error("只能是正整数"));
                  return;
                }
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        min_coupon_amount: floatRules,
        max_coupon_amount: floatRules,
      },
      topicList: [],
      goodList: [],
      total: 0,
      pagesize: 100,
      page: 1,
      timeId: null,
      showDetailsDialog: false,
      detailsData: {},
      showEditDialog: false,
      editId: 0,
    };
  },
  created() {
    this.init();
    this.getSnOrderList();
  },
  methods: {
    showAddTopicDialog() {
      const sortKeys = [
        "",
        "commission_rate",
        "commission_rate",
        "end_price",
        "end_price",
        "month_sales",
        "month_sales",
      ];
      const sortTypes = ["", "1", "2", "1", "2", "1", "2"];
      this.showSaveTopicDialogParams = Object.assign(
        {
          keyword: this.searchWord,
          page: this.page,
          pagesize: this.pagesize,
          sort_key: sortKeys[this.filterValue],
          type: sortTypes[this.filterValue],
          first_own_cate_id: this.ftBoxClassId,
        },
        this.form
      );
      this.showSaveTopicDialog = true;
    },
    addEditDialog(editId) {
      this.showEditDialog = true;
      this.editId = editId || "";
    },
    showDetails(item) {
      this.detailsData = item;
      this.showDetailsDialog = true;
    },
    tabIndexChange(index) {
      if (this.tabIndex == index) {
        return;
      }
      this.tabIndex = index;
    },
    ftBoxClassIdChange(id) {
      if (this.ftBoxClassId == id) {
        return;
      }
      this.ftBoxClassId = id;
      this.refresh();
    },
    ftBoxClassBodyOverflowChange() {
      const ftBoxClassBodyOverflow = !this.ftBoxClassBodyOverflow;

      this.ftBoxClassBodyOverflow = ftBoxClassBodyOverflow;
    },
    init() {
      api.getSellingClassifyList(
        {
          level: 1,
        },
        (res) => {
          const list = res.data.list;
          if (list && list.length > 0) {
            list.unshift({
              id: "-1",
              name: "全部",
            });
            this.classNavs = list;
          }
        }
      );
      api.getTopicByKeywordList(
        {
          pagesize: 100,
        },
        (res) => {
          this.topicList = res.data.list;
        }
      );
    },
    sizeChange(val) {
      this.pagesize = val;
      this.getSnOrderList();
    },
    currentChange(val) {
      this.page = val;
      this.getSnOrderList();
    },
    getSnOrderList() {
      const sortKeys = [
        "",
        "commission_rate",
        "commission_rate",
        "end_price",
        "end_price",
        "month_sales",
        "month_sales",
      ];
      const sortTypes = ["", "1", "2", "1", "2", "1", "2"];
      const params = Object.assign(
        {
          keyword: this.searchWord,
          page: this.page,
          pagesize: this.pagesize,
          sort_key: sortKeys[this.filterValue],
          type: sortTypes[this.filterValue],
          first_own_cate_id: this.ftBoxClassId,
        },
        this.form
      );
      let loadingInstance = Loading.service({
        target: this.$refs.app,
        background: "rgba(0,0,0,.8)",
        spinner: "el-icon-loading",
      });
      api.getGoodLibList(params, (res) => {
        this.total = res.data.count * 1;
        this.goodList = res.data.list;
        loadingInstance.close();
      });
    },

    refresh() {
      this.page = 1;
      this.getSnOrderList();
    },

    inputChange() {
      if (this.timeId) {
        clearTimeout(this.timeId);
      }
      const form = this.$refs.form;
      form.validate((valid) => {
        if (!valid) {
          if (this.timeId) {
            clearTimeout(this.timeId);
          }
          return;
        }
        this.timeId = setTimeout(() => {
          this.refresh();
        }, 800);
      });
    },
    jump(type) {
      this.$router.push({
        path: "/commodity/import/index",
        query: {
          type: type == 0 ? "tbDialogVisible" : "excelDialogVisible",
        },
      });
    },
  },
};
</script>